import { getLanguageCode, I18N_GUARD_CONFIG, I18nGuardConfig, Lang } from '@aimmo/i18n';
import { RxNgForage } from '@aimmo/services/ngforage';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { defer, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

const LANGUAGE_QUERY_PARAM = 'lang';
export const DEFAULT_LANGUAGE = Lang.EN;

@Injectable({
  providedIn: 'root'
})
export class I18nGuard {
  private initialized = false;

  constructor(
    @Inject(I18N_GUARD_CONFIG) private config: I18nGuardConfig,
    private translate: TranslateService,
    private rxNgForage: RxNgForage
  ) {
    this.translate.addLangs(this.config.supportLanguages);
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return defer(() => {
      if (route.queryParamMap.has(LANGUAGE_QUERY_PARAM)) {
        const lang = route.queryParamMap.get(LANGUAGE_QUERY_PARAM) as string;
        const languageCode = getLanguageCode(lang);
        if (languageCode && this.config.supportLanguages.includes(languageCode)) {
          this.initialized = true;
          return this.setLanguage(lang);
        }
      }
      return this.initialSetLanguage();
    }).pipe(
      map(() => true)
    );
  }

  public setLanguage(lang?: string): Observable<string | undefined> {
    return defer(() => {
      const languageCode = getLanguageCode(lang);
      if (languageCode && this.config.supportLanguages.includes(languageCode)) {
        return this.translate.use(lang).pipe(
          switchMap(() => this.rxNgForage.setItem<string>(this.config.savedLanguageKey, lang)),
          catchError(() => of(undefined))
        );
      }
      return of(undefined);
    });
  }

  private initialSetLanguage(): Observable<boolean> {
    return defer(() => {
      if (this.initialized) {
        return of(true);
      }
      this.initialized = true;
      return this.rxNgForage.getItem<string>(this.config.savedLanguageKey).pipe(
        switchMap(savedLang => {
          if (savedLang) {
            const languageCode = getLanguageCode(savedLang);
            if (languageCode && this.config.supportLanguages.includes(languageCode)) {
              return this.translate.use(savedLang);
            }
          }
          let browserLang = this.translate.getBrowserLang() as Lang;
          if (!this.config.supportLanguages.includes(browserLang)) {
            browserLang = DEFAULT_LANGUAGE;
          }
          return this.translate.use(browserLang);
        })
      );
    });
  }
}
