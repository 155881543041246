import { InjectionToken } from '@angular/core';
import { isEqual } from 'lodash-es';

export enum Lang {
  KO = 'ko',
  EN = 'en',
  VI = 'vi',
  JA = 'ja'
}

export const LANGUAGE_CODES = [
  Lang.KO,
  Lang.EN,
  Lang.VI,
  Lang.JA
];

export const COUNTRY_CODES = {
  KR: 'KR',
} as const;

export const PHONE_COUNTRY_CODES = {
  US: '+1',
  KR: '+82'
} as const;

export interface PhoneCountryCodes {
  isoCode: string;
  numberCode: string;
}

export interface Countries {
  isoCode: string;
  name: string;
}

export function getLanguageCode(code: string): Lang | undefined {
  return LANGUAGE_CODES.find(languageCode => isEqual(code, languageCode));
}

export const SupportLanguages = {
  admin: [Lang.KO, Lang.EN],
  dassDatasetViewer: [Lang.KO, Lang.EN],
  external: LANGUAGE_CODES,
  labelers: LANGUAGE_CODES,
  labelersCsCenter: [Lang.KO, Lang.EN, Lang.VI],
  labelersInhouse: [Lang.KO, Lang.EN, Lang.VI],
  gtaas: LANGUAGE_CODES,
  aimmoCore: [Lang.KO, Lang.EN],
  aimmoCore2: [Lang.KO, Lang.EN],
  aimmoCoreAdmin: [Lang.KO],
  aimmoCoreAzure: [Lang.KO, Lang.EN],
} as const;

export const SavedLanguageKey = {
  admin: 'RENEWAL_HOME_LANGUAGE',
  dassDatasetViewer: 'RENEWAL_HOME_LANGUAGE',
  aimmoCore: 'RENEWAL_HOME_LANGUAGE',
  aimmoCore2: 'AIMMO_CORE2_HOME_LANGUAGE',
  aimmoCoreAdmin: 'AIMMO_CORE_ADMIN_HOME_LANGUAGE',
  aimmoCoreAzure: 'AIMMO_CORE_AZURE_LANGUAGE',
  external: 'EIMMO_LANGUAGE',
  labelers: 'MIMMO_LANGUAGE',
  labelersCsCenter: 'MIMMO_LANGUAGE',
  labelersInhouse: 'MIMMO_LANGUAGE',
  gtaas: 'EIMMO_LANGUAGE'
} as const;


export interface I18nGuardConfig {
  supportLanguages: Lang[];
  savedLanguageKey: string;
}

export const I18N_GUARD_CONFIG = new InjectionToken<I18nGuardConfig>('I18nGuardConfig');
